import React from "react"
import tw, { styled } from "twin.macro"
import "styled-components/macro"
import useForm from "../hooks/useForm"
import { getInput } from "./Inputs"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Form = styled.form`
  ${tw`w-full`}
`
const GridContainer = styled.div`
  ${tw`grid grid-cols-12 gap-2.5`}
`
const GridItem = styled.div`
  grid-column: ${props => `span ${props.span} / span ${props.span}`};
`
const SubmitButton = styled.button`
  ${tw`px-4 py-2 mt-1 bg-[#EC1F27] text-white font-bold rounded text-sm disabled:grayscale transition-[filter] duration-200`}
`

const ContactForm = () => {
  const {
    handleChange,
    handleSubmit,
    submitted,
    error,
    loading,
    errorMessage,
  } = useForm({})
  const breakpoints = useBreakpoint()
  const isMidScreen =
    Object.values(breakpoints).reduce((a, b) => (b ? a + 1 : a), 0) > 1
  return (
    <Form name="contact-form" onSubmit={handleSubmit}>
      <GridContainer>
        <GridItem span={isMidScreen ? 6 : 12}>
          {getInput({
            handleChange,
            inputType: "text",
            label: "name",
            required: true,
          })}
        </GridItem>
        <GridItem span={isMidScreen ? 6 : 12}>
          {getInput({
            handleChange,
            inputType: "phone",
            label: "phone",
            required: true,
          })}
        </GridItem>
        <GridItem span={12}>
          {getInput({
            handleChange,
            inputType: "email",
            label: "email",
            required: true,
          })}
        </GridItem>
        <GridItem span={12}>
          {getInput({
            handleChange,
            inputType: "textarea",
            label: "message",
            required: true,
          })}
        </GridItem>
        <GridItem span={12} tw="flex items-center justify-between">
          <SubmitButton disabled={loading || submitted}>
            Send Message
          </SubmitButton>
          {error && <span tw="text-[#EC1F27]">{errorMessage}</span>}
          {submitted && (
            <span tw="text-black">{"Message successfully sent!"}</span>
          )}
        </GridItem>
      </GridContainer>
    </Form>
  )
}

export default ContactForm
